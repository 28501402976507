import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { ResetpassauthComponent } from './resetpassauth/resetpassauth.component';
import { ShowvideoComponent } from './showvideo/showvideo.component';
import { AliasnameComponent } from './showvideo/aliasname/aliasname.component';
import { ProtectpasswordComponent } from './showvideo/protectpassword/protectpassword.component';
import { OnetimepassComponent } from './showvideo/onetimepass/onetimepass.component';
import { TnakyouComponent } from './showvideo/tnakyou/tnakyou.component';
import { StudentimageComponent } from './showvideo/studentimage/studentimage.component';
import { AutholdComponent } from './authold/authold.component';
import { ForgotpassoldComponent } from './forgotpassold/forgotpassold.component';
import { ProtectpasstempComponent } from './showvideo/protectpasstemp/protectpasstemp.component';
import { ProjectorComponent } from './projector/projector.component';
import { StudentliveComponent } from './studentlive/studentlive.component';
import { WelcomeComponent } from './showvideo/welcome/welcome.component';
import { CompetitionComponent } from './showvideo/competition/competition.component';
import { EnrolledcourseComponent } from './enrolledcourse/enrolledcourse.component';
import { AuthGuardServiceService } from './auth-guard-service.service';
import { AppComponent } from './app.component';
import { MembershipComponent } from './membership/membership.component';
import { OnetimepaymentComponent } from './onetimepayment/onetimepayment.component';
import { SelfpacedvideoComponent } from './showvideo/selfpacedvideo/selfpacedvideo.component';
import { LearningvideoComponent } from './showvideo/learningvideo/learningvideo.component';
import { CustomscheduleComponent } from './showvideo/customschedule/customschedule.component';
import { TimezonesetComponent } from './showvideo/timezoneset/timezoneset.component';
import { PassexamComponent } from './pages/survey/passexam/passexam.component';
import { FeelaboutclassComponent } from './pages/survey/feelaboutclass/feelaboutclass.component';
import { PrepareexamComponent } from './pages/survey/prepareexam/prepareexam.component';
import { EffectivepartComponent } from './pages/survey/effectivepart/effectivepart.component';
import { ImproveclassComponent } from './pages/survey/improveclass/improveclass.component';
import { AdditionalfeedbackComponent } from './pages/survey/additionalfeedback/additionalfeedback.component';
import { FeedbackjohnComponent } from './pages/survey/feedbackjohn/feedbackjohn.component';
import { FeedbackrobComponent } from './pages/survey/feedbackrob/feedbackrob.component';
import { TrainingclassComponent } from './pages/survey/trainingclass/trainingclass.component';
import { FriendcolleagueComponent } from './pages/survey/friendcolleague/friendcolleague.component';
import { SurveythankyouComponent } from './pages/survey/surveythankyou/surveythankyou.component';
import { DomainresultComponent } from './pages/survey/domainresult/domainresult.component';
import { EventrecordingsComponent } from './pages/eventrecordings/eventrecordings.component';
import { StudentskinComponent } from './pages/studentskin/studentskin.component';
import { AuthypasswordComponent } from './pages/authypassword/authypassword.component';
import { VerifyauthyComponent } from './pages/verifyauthy/verifyauthy.component';
import { RecoveryemailsuccessComponent } from './recoveryemailsuccess/recoveryemailsuccess.component';
import { ForcechangepasswordcComponent } from './pages/forcechangepasswordc/forcechangepasswordc.component';
import { PaySuccessComponent } from './showvideo/pay-success/pay-success.component';
import { StudentreportComponent } from './pages/studentreport/studentreport.component';
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'authentication/:email', component: AuthenticationComponent },
  { path: 'resetpassauth/:email', component: ResetpassauthComponent },
  { path: 'welcome/:data', component: ShowvideoComponent },
  { path: 'alias/:data', component: AliasnameComponent },
  { path: 'protectpass/:data', component: ProtectpasswordComponent },
  { path: 'onetimepsas/:data', component: OnetimepassComponent },
  { path: 'thankyou/:data', component: TnakyouComponent },
  { path: 'userimage/:data', component: StudentimageComponent },
  { path: 'auth/:data', component: AutholdComponent },
  { path: 'forgot/:data', component: ForgotpassoldComponent },
  { path: 'protecttemp/:data', component: ProtectpasstempComponent },
  { path: 'welcomertemp/:data', component: WelcomeComponent },
  { path: 'pay-success/:data', component: PaySuccessComponent },
  { path: 'competition/:data', component: CompetitionComponent },
  { path: 'enrolledcourse', component: EnrolledcourseComponent },
  { path: 'membership/:data', component: MembershipComponent },
  { path: 'onetimepayment/:data', component: OnetimepaymentComponent },
  { path: 'selfpacedvideo/:data', component: SelfpacedvideoComponent },
  { path: 'learning/:data', component: LearningvideoComponent },
  { path: 'customschedule/:data', component: CustomscheduleComponent },
  { path: 'timezone/:data', component: TimezonesetComponent },
  {
    path: 'examresult/:data',
    component: PassexamComponent,
    canActivate: [AuthGuardServiceService],
  },
  { path: 'aboutclass', component: FeelaboutclassComponent },
  { path: 'prepareexam', component: PrepareexamComponent },
  { path: 'effectivepart', component: EffectivepartComponent },
  { path: 'improveclass', component: ImproveclassComponent },
  { path: 'additionalfeedback', component: AdditionalfeedbackComponent },
  { path: 'feedbackjohn', component: FeedbackjohnComponent },
  { path: 'feedbackrob', component: FeedbackrobComponent },
  { path: 'trainingclass', component: TrainingclassComponent },
  { path: 'friendcolleague', component: FriendcolleagueComponent },
  { path: 'surveythankyou', component: SurveythankyouComponent },
  { path: 'domainresult', component: DomainresultComponent },
  { path: 'eventrecordings/:eventid', component: EventrecordingsComponent },
  { path: 'studentskin', component: StudentskinComponent },
  {
    path: 'practicetestque/:testid',
    loadChildren: () =>
      import('./pages/practicetestquestion/practicetestquestion.module').then(
        (m) => m.PracticetestquestionModule,
      ),
  },
  {
    path: 'cateexamtestque/:testid',
    loadChildren: () =>
      import('./pages/cateexamtestque/cateexamtestque.module').then(
        (m) => m.CateexamtestqueModule,
      ),
  },
  {
    path: 'videolesson',
    loadChildren: () =>
      import('./pages/videolesson/videolesson.module').then(
        (m) => m.VideolessonModule,
      ),
    canActivate: [AuthGuardServiceService],
  },
  {
    path: 'videolesson/:id',
    loadChildren: () =>
      import('./pages/videolesson/videolesson.module').then(
        (m) => m.VideolessonModule,
      ),
    canActivate: [AuthGuardServiceService],
  },
  {
    path: 'mindmaps/:id',
    loadChildren: () =>
      import('./pages/mindmaps/mindmaps.module').then((m) => m.MindmapsModule),
    canActivate: [AuthGuardServiceService],
  },
  {
    path: 'mindmaps',
    loadChildren: () =>
      import('./pages/mindmaps/mindmaps.module').then((m) => m.MindmapsModule),
    canActivate: [AuthGuardServiceService],
  },
  { path: 'projector/:id', component: ProjectorComponent },
  { path: 'studentlive/:id', component: StudentliveComponent },
  {
    path: 'mindmaps',
    loadChildren: () =>
      import('./pages/mindmaps/mindmaps.module').then((m) => m.MindmapsModule),
  },
  {
    path: 'conference/:id',
    loadChildren: () =>
      import('./pages/conference/conference.module').then(
        (m) => m.ConferenceModule,
      ),
  },
  {
    path: 'startconference/:id',
    loadChildren: () =>
      import('./pages/startconference/startconference.module').then(
        (m) => m.StartconferenceModule,
      ),
  },
  {
    path: 'sidenav',
    component: SidenavComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
        canActivate: [AuthGuardServiceService],
      },
      {
        path: 'study',
        loadChildren: () =>
          import('./pages/review-guide/review-guide.module').then(
            (m) => m.ReviewGuideModule,
          ),
        canActivate: [AuthGuardServiceService],
      },
      {
        path: 'personalized-review-guide',
        loadChildren: () =>
          import(
            '../app/v2/pages/personalized-review-guide/personalized-review-guide.module'
          ).then((m) => m.PersonalizedReviewGuideModule),
      },
      {
        path: 'courseschedule',
        loadChildren: () =>
          import('../app/v2/pages/course-schedule/course-schedule.module').then(
            (m) => m.CourseScheduleModule,
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
        canActivate: [AuthGuardServiceService],
      },
      {
        path: 'knowledge',
        loadChildren: () =>
          import('./pages/knowledge/knowledge.module').then(
            (m) => m.KnowledgeModule,
          ),
        canActivate: [AuthGuardServiceService],
      },
      {
        path: 'knowledge/:id',
        loadChildren: () =>
          import('./pages/knowledge/knowledge.module').then(
            (m) => m.KnowledgeModule,
          ),
        canActivate: [AuthGuardServiceService],
      },
      {
        path: 'knowledgeQue',
        loadChildren: () =>
          import('./pages/knowquestion/knowquestion.module').then(
            (m) => m.KnowquestionModule,
          ),
      },
      {
        path: 'cateexamtest',
        loadChildren: () =>
          import('./pages/cateexamtest/cateexamtest.module').then(
            (m) => m.CateexamtestModule,
          ),
      },
      {
        path: 'cateexamtestque/:testid',
        loadChildren: () =>
          import('./pages/cateexamtestque/cateexamtestque.module').then(
            (m) => m.CateexamtestqueModule,
          ),
      },
      {
        path: 'cateexamtestresult/:testid',
        loadChildren: () =>
          import('./pages/cateexamresult/cateexamresult.module').then(
            (m) => m.CateexamresultModule,
          ),
      },

      {
        path: 'practicetest',
        loadChildren: () =>
          import('./pages/practicetest/practicetest.module').then(
            (m) => m.PracticetestModule,
          ),
      },
      {
        path: 'practicetestque/:testid',
        loadChildren: () =>
          import(
            './pages/practicetestquestion/practicetestquestion.module'
          ).then((m) => m.PracticetestquestionModule),
      },
      {
        path: 'practicetestresult/:testid',
        loadChildren: () =>
          import(
            './pages/practicetestquestionresult/practicetestquestionresult.module'
          ).then((m) => m.PracticetestquestionresultModule),
      },
      {
        path: 'knowledgeresult/:data',
        loadChildren: () =>
          import('./pages/knowledgeresult/knowledgeresult.module').then(
            (m) => m.KnowledgeresultModule,
          ),
      },

      {
        path: 'knowledgeresult/:id/:cid',
        loadChildren: () =>
          import('./pages/knowledgeresult/knowledgeresult.module').then(
            (m) => m.KnowledgeresultModule,
          ),
      },
      {
        path: 'videolesson',
        loadChildren: () =>
          import('./pages/videolesson/videolesson.module').then(
            (m) => m.VideolessonModule,
          ),
        canActivate: [AuthGuardServiceService],
      },
      {
        path: 'videolesson/:id',
        loadChildren: () =>
          import('./pages/videolesson/videolesson.module').then(
            (m) => m.VideolessonModule,
          ),
        canActivate: [AuthGuardServiceService],
      },
      {
        path: 'mindmaps/:id',
        loadChildren: () =>
          import('./pages/mindmaps/mindmaps.module').then(
            (m) => m.MindmapsModule,
          ),
      },
      {
        path: 'mindmaps',
        loadChildren: () =>
          import('./pages/mindmaps/mindmaps.module').then(
            (m) => m.MindmapsModule,
          ),
      },
      {
        path: 'chatting',
        loadChildren: () =>
          import('./pages/chatting/chatting.module').then(
            (m) => m.ChattingModule,
          ),
      },
      {
        path: 'live-support',
        loadChildren: () =>
          import('./pages/live-support/live-support.module').then(
            (m) => m.LiveSupportModule,
          ),
      },
      {
        path: 'notification',
        loadChildren: () =>
          import('./pages/notification/notification.module').then(
            (m) => m.NotificationModule,
          ),
      },
      {
        path: 'resource',
        loadChildren: () =>
          import('./pages/resource/resource.module').then(
            (m) => m.ResourceModule,
          ),
      },
      {
        path: 'schedule',
        loadChildren: () =>
          import('./pages/schedule/schedule.module').then(
            (m) => m.ScheduleModule,
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./pages/search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'studentprofile',
        loadChildren: () =>
          import('./pages/studentprofile/studentprofile.module').then(
            (m) => m.StudentprofileModule,
          ),
      },
      {
        path: 'studentprofile/:id',
        loadChildren: () =>
          import('./pages/studentprofile/studentprofile.module').then(
            (m) => m.StudentprofileModule,
          ),
      },
      {
        path: 'faqquestion',
        loadChildren: () =>
          import('./pages/faqquestion/faqquestion.module').then(
            (m) => m.FaqquestionModule,
          ),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./pages/support/support.module').then((m) => m.SupportModule),
      },
    ],
  },
  {
    path: 'pages/knowledge',
    loadChildren: () =>
      import('./pages/knowledge/knowledge.module').then(
        (m) => m.KnowledgeModule,
      ),
  },
  {
    path: 'pages/knowledgeresult',
    loadChildren: () =>
      import('./pages/knowledgeresult/knowledgeresult.module').then(
        (m) => m.KnowledgeresultModule,
      ),
  },

  {
    path: 'pages/videolesson',
    loadChildren: () =>
      import('./pages/videolesson/videolesson.module').then(
        (m) => m.VideolessonModule,
      ),
  },
  {
    path: 'pages/studentskin',
    loadChildren: () =>
      import('./pages/studentskin/studentskin.module').then(
        (m) => m.StudentskinModule,
      ),
  },
  {
    path: 'pages/Knowquestion',
    loadChildren: () =>
      import('./pages/knowquestion/knowquestion.module').then(
        (m) => m.KnowquestionModule,
      ),
  },
  {
    path: 'pages/practicetest',
    loadChildren: () =>
      import('./pages/practicetest/practicetest.module').then(
        (m) => m.PracticetestModule,
      ),
  },
  {
    path: 'pages/practicetestquestion',
    loadChildren: () =>
      import('./pages/practicetestquestion/practicetestquestion.module').then(
        (m) => m.PracticetestquestionModule,
      ),
  },
  {
    path: 'pages/practiceresult',
    loadChildren: () =>
      import(
        './pages/practicetestquestionresult/practicetestquestionresult.module'
      ).then((m) => m.PracticetestquestionresultModule),
  },
  {
    path: 'pages/calculator',
    loadChildren: () =>
      import('./pages/calculator/calculator.module').then(
        (m) => m.CalculatorModule,
      ),
  },
  {
    path: 'pages/cateexamtest',
    loadChildren: () =>
      import('./pages/cateexamtest/cateexamtest.module').then(
        (m) => m.CateexamtestModule,
      ),
  },
  {
    path: 'pages/cateexamtestque',
    loadChildren: () =>
      import('./pages/cateexamtestque/cateexamtestque.module').then(
        (m) => m.CateexamtestqueModule,
      ),
  },
  {
    path: 'pages/cateexamresult',
    loadChildren: () =>
      import('./pages/cateexamresult/cateexamresult.module').then(
        (m) => m.CateexamresultModule,
      ),
  },
  {
    path: 'pages/chatting',
    loadChildren: () =>
      import('./pages/chatting/chatting.module').then((m) => m.ChattingModule),
  },
  {
    path: 'pages/notification',
    loadChildren: () =>
      import('./pages/notification/notification.module').then(
        (m) => m.NotificationModule,
      ),
  },
  {
    path: 'pages/resource',
    loadChildren: () =>
      import('./pages/resource/resource.module').then((m) => m.ResourceModule),
  },
  {
    path: 'pages/usersetting',
    loadChildren: () =>
      import('./pages/usersetting/usersetting.module').then(
        (m) => m.UsersettingModule,
      ),
  },
  {
    path: 'pages/changepassword',
    loadChildren: () =>
      import('./pages/changepassword/changepassword.module').then(
        (m) => m.ChangepasswordModule,
      ),
  },
  {
    path: 'pages/twofasetting',
    loadChildren: () =>
      import('./pages/twofasetting/twofasetting.module').then(
        (m) => m.TwofasettingModule,
      ),
  },
  {
    path: 'pages/myenrollments',
    loadChildren: () =>
      import('./pages/myenrollments/myenrollments.module').then(
        (m) => m.MyenrollmentsModule,
      ),
  },
  {
    path: 'pages/faqquestion',
    loadChildren: () =>
      import('./pages/faqquestion/faqquestion.module').then(
        (m) => m.FaqquestionModule,
      ),
  },
  {
    path: 'pages/support',
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportModule),
  },
  {
    path: 'pages/resource',
    loadChildren: () =>
      import('./pages/resource/resource.module').then((m) => m.ResourceModule),
  },
  {
    path: 'noconnection',
    loadChildren: () =>
      import('./noconnection/noconnection.module').then(
        (m) => m.NoconnectionModule,
      ),
  },
  {
    path: 'certificate/:certificate',
    loadChildren: () =>
      import('./certificate/certificate.module').then(
        (m) => m.CertificateModule,
      ),
  },
  { path: 'pages/authy/:data', component: AuthypasswordComponent },
  { path: 'pages/verifyauthy/:data', component: VerifyauthyComponent },
  {
    path: 'recoverymailsuccess/:data',
    component: RecoveryemailsuccessComponent,
  },
  {
    path: 'pages/forcechangepassword/:data',
    component: ForcechangepasswordcComponent,
  },
  { path: 'studentreport/:id', component: StudentreportComponent },
  {
    path: 'pages/startconference',
    loadChildren: () =>
      import('./pages/startconference/startconference.module').then(
        (m) => m.StartconferenceModule,
      ),
  },
  { path: '**', component: AppComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
