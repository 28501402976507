import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http, Headers, RequestOptions } from '@angular/http';
import { CryptoService } from '../crypto/crypto.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VideoserviceService {
  apiUrl = 'http://localhost:3000/api/video';
  baseurl = environment.baseurl;
  constructor(
    public http: Http,
    private crypto: CryptoService,
    private http2: HttpClient,
  ) {}
  async GetAllVideoLesson(token, userId, c_id, ids, video_id) {
    var cohort_id = localStorage.getItem('cohort_id');
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          c_id: c_id,
          user_id: userId,
          cohort_id: cohort_id,
          ids: ids,
          video_id: video_id,
          certi_id: c_id,
        }),
      ),
    };
    var url = this.baseurl + environment.VIDEO_BY_COURCE;
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });

      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async GetTranscriptFile(url) {
    return new Promise((resolve) => {
      this.http.get(url).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async VideoNoteAdd(user_id, video_id, notes, notes_type, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          video_id: video_id,
          notes: notes,
          notes_type: notes_type,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.VIDEO_NOTE_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async GetVideoNotesByUser(user_id, video_id, notes_type, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: user_id,
          video_id: video_id,
          notes_type: notes_type,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.VIDEO_NOTEBY_USER;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async AddVideoDiscussion(from, to, v_id, message, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          from: from,
          to: to,
          v_id: v_id,
          message: message,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.DISSCUSSION_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async GetVideoDiscussion(v_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          v_id: v_id,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.DISSCUSSION_BY;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async VideoUpdate(token, VideoUpdate) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(VideoUpdate)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.VIDEO_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async getShortUrl(qr_code) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          qr_code: qr_code,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.URL_SHORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async getEventRecording(EventRecording, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(EventRecording)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_EV_RECORD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }

  async getVideoById(videoId: any, token: any) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          videoId: videoId,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.VIDEO_BY_ID;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
