import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { VideoserviceService } from './services/video/videoservice.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginflowService } from './data/loginflow.service';
import { ServicesService } from './services.service';
import { AuthserviceService } from './authservice.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StudyguideService } from './data/studyguide.service';
import { SharedDataService } from './services/SharedData/shared-data.service';
import { KnowledgeserviceService } from './services/knowledge/knowledgeservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'studentdashboard';

  constructor(
    public route: Router,
    public VideoserviceService: VideoserviceService,
    private cookieService: CookieService,
    public LoginflowService: LoginflowService,
    public service: ServicesService,
    public auth: AuthserviceService,
    public sanitizer: DomSanitizer,
    public studyGuideService: StudyguideService,
    public sharedDataService: SharedDataService,
    public knowledgeService: KnowledgeserviceService,
    public router: Router,
  ) {
    sessionStorage.setItem('clearInterval', 'true');
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        var userdetails = localStorage.getItem('userdetails');
        if (
          userdetails != undefined &&
          userdetails != null &&
          userdetails != ''
        ) {
          var userdata = JSON.parse(<string>userdetails);
          this.LoginflowService.versions_getUserById(userdata['id']).then(
            (data) => {
              var list = JSON.parse(<string>data[0].json);
              if (list.data) {
                if (list.data.length <= 0) {
                  localStorage.clear();
                  sessionStorage.clear();
                  this.router.navigate(['login']);
                }
              }
            },
          );
        }
      }
    });
    let PassExamurl = this.route.url;
    let url = this.route.url;
    url = url.substring(1, url.length);
    if (url.startsWith('D')) {
      this.VideoserviceService.getShortUrl(url).then(async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          var v = btoa(JSON.stringify(list.data[0].id));
          this.route.navigate([`/videolesson/${v}`]);
        } else {
          this.route.navigate(['/login']);
        }
      });
    } else if (url.substring(0, 2) == 'CL') {
      url = url.replace('%2F', '/');
      let ur = '/' + url.split('/')[1] + '/' + url.split('/')[2];
      this.route.navigate([ur]);
    }
    window.onbeforeunload = function (e) {
      return 'Please press the Logout button to logout.';
    };
  }
}
